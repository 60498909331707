.hero {
    height: 900px;
    width: 100%;
    margin-top: -100px;
}

video {
    width: 100%;
    height: 900px;
    object-fit: cover;
}

.content {
    width: 100%;
    height: 100%;
    margin-top: -5%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    align-items: center;
    color: white;
    text-align: center;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 900px;
    background-color: rgba(0, 0, 0, .5);
}

.headingIntro {
    margin-bottom: 0;
}

.headingContent {
    margin-top: 0;
}

.headingIntroHome {
    font-weight: 300;
}

@media only screen and (max-width: 700px) {
    .content {
        margin-top: -35%;
    }
    .headingContent {
        font-size: 60px;
    }
    .hero {
        height: 700px;
    }
    video {

        height: 700px;
    }
    .overlay {
        height: 700px;
    }
    
}
@media only screen and (max-width: 1600px) {
    .content {
        margin-top: 0%;
    }
    .headingContent{
        font-size: 70px;
        font-weight: 600;
        margin-top: 0%;
    }
}