.footer {
    background-color: rgb(32, 32, 32);
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgb(32, 32, 32);
    height: 70px;
}

.fText {
    font-weight: 300;
}


@media only screen and (max-width: 600px) {
    .footer{
        padding: 2% 5%;
    }}

@media only screen and (max-width: 600px) {
    .fText{
        font-size: 14px;
    }}