.section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 3% 4%;
    background-attachment:fixed  ;
}


.leftSide {
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 5%;
}

.dp {
    border-radius: 5px;
}

.rightSide {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1%;
}

.infoHome {
    padding: 4%;
    background: var(--purple);
    border-radius: 5px;
    box-shadow: 10px 10px 8px #202020;

}



.buttonSection {
    width: 100%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: row;
    

}

.buttonLeft, .buttonRight {
    width: 50%;
    display: flex;
}

.contactBtn, .resBtn {
    text-decoration: none;
    margin-top: 3%;
    padding: 2% 6% ;
    background-color: white;
    color: var(--purple);
    cursor: pointer;
    transition: .3s;
    border-radius: 30px;
    border: solid 2px white;
}

.contactBtn:hover, .resBtn:hover {
    transform: scale(1.1);
    border-radius: 30px;
    box-shadow: 2px 2px 8px #000000;
    background-color: var(--grey);
    color: white;
    border: solid 2px var(--grey);
}

.bHeading {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 3%;
}

.bodyText {
    color: rgb(235, 235, 235);
    font-weight: 300;
}


@media only screen and (max-width: 1400px) {
    .section {
        flex-direction: row;
        overflow: hidden;
        padding: 5%;

    }
}
@media only screen and (max-width: 1270px) {
    .section {
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        align-content: center;
    }
}
@media only screen and (max-width: 1270px) {
    .leftSide {
        margin-top: 5%;
        padding: 5% 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
    }
}
@media only screen and (max-width: 600px) {
    .dp {
        width: 350px;
    }
}
@media only screen and (max-width: 1270px) {
    .rightSide {
        margin-bottom: 5%;
        padding: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: auto;
    }
}
@media only screen and (max-width: 600px) {
    .rightSide {
        margin-bottom: 5%;
        padding: 10% 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}
@media only screen and (max-width: 600px) {
    .resBtn, .contactBtn {
        padding: 5% 5%;
    }
}
@media only screen and (max-width: 600px) {
    .infoHome {
        margin: 0;
        padding: 10%;
    }
}
@media only screen and (max-width: 600px) {
    .bioHeading {
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 5%;
    }
}
@media only screen and (max-width: 600px) {
    .bodyText {
        font-size: 19px;
    }
}








/* 
.section {
    height: auto;
    width: auto;
    margin: 0;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat; 
    background-attachment: fixed; 
    background-position: center;
    background-size: cover;
    object-fit: cover;

}


.leftSide {
    width: 50%;
    height: auto;
    z-index: 1;

}

.rightSide {
    width: 50%;
    height: auto;
    padding: 5%;
    background-color: var(--purple);
    border-radius: 5px;
    z-index: 1;
}

.info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.dp {
    border-radius: 5px;
}



.projectH1, .projectP {
    color: var(--purple);
}






*/