.header {
    height: 100px;
    width: 100%;
    background-color: rgba(51, 51, 51, 0.445);
    display: flex;
    flex-direction: row;
    
    padding: 0 5%;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 55;
}

h1 {
    color: white;
    font-size: 50px;
}
.brand {
    color: white;
    font-size: 30px;
    font-weight: 300;
    text-decoration: none;
}


.left {
    width: 33%;
    display: flex;
    justify-content: start;
    position: sticky;
    align-items: center;
}

.middle {
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.right {
    width: 33%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.logo {
    margin: 0 1%;
}

@media only screen and (max-width: 660px) {
    .brand {
        display: none;
    }
    .left {
        display: none;
    }
    .middle {
        width: 100%;
    }

    .navbar {
        justify-content: start;
    }
    .logo {
        margin: 0 6%;
    }
}