.bodyContact {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    background: white;
    color: black;
    padding: 3% 3%;
}

.imageLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 4%

}

.bcImage {
    width: 700px;
    border-radius: 5px;
    box-shadow: 10px 10px 8px #888888;
}

.infoRight {
    background: var(--purple);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0 2%;
    border-radius: 5px;
}

.cHeading {
    font-size: 40px;
    margin-bottom: 5%;
    line-height: 1;
}

.bioInfo {
    width: 70%;
}
.subHeading {
    font-size: 16px;
}

/* Styling for Mobile */
@media only screen and (max-width: 700px) {
    .bioInfo {
        padding: 10% 5%;
        width: 100%;
    }
    .form {
        width: 100%;
        padding: 0 5%;
        margin: 5% 0 10%;
    }
    .infoRight {
        flex-direction: column;
        width: 100%;
    }.bcImage {
        width: 355px;

    }.bodyContact {
        flex-direction: column;
        padding: 5% 5%;
    }
    .bcImage {
        width: 500px;
    }
}

    /* Styling for Laptop */
    @media only screen and (max-width: 1600px) {
        .bcImage {
            width: 100%;
        }
    }