@import url('https://fonts.googleapis.com/css?family=Poppins');

:root {
  --purple: #474787; 
  --grey: #333333
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
}

.site {
  /*background-color: rgb(32, 32, 32);*/
  color: white;
  margin: 0;
  padding: 0;
  
}

/* Project Page styling */
.infoCardSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.projectCardSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 2% 4%;
}

.projectHeading {
  font-size: 50px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3% 0 -1% 0;
}

@media only screen and (max-width: 700px) {
  .infoCardSection {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }}

@media (max-width: 700px) {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Poppins";
  }
  .projectCardSection {
    flex-direction: column;
    background-color: var(--grey);
    padding: 5%;
  }
  .projectHeading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

  @media (max-width: 1600px) {
    .projectHeading {
        margin: 3% 0 0% 0;
    }
}



/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/