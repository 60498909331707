.infoCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--purple);
    width: 50%;
    border-radius: 5px;
    padding: 2%;
    box-shadow: 10px 10px 8px #888888;
}

.infoCardP {
    text-align: center;
    font-weight: 400;
    margin-top: 2%;
    color: black;
}

@media only screen and (max-width: 600px) {
    .infoCardContainer {
        display: flex;
        width: 100%;
        margin: 7%;
        padding: 5%;
        justify-content: center;
        align-items: center;
    }}



