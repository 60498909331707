.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: var(--purple);
    width: 80%;
    border-radius: 5px;
    box-shadow: 10px 10px 8px #000000;
    padding: 1.5% 0;

}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 5% 0;

}

.form-label, .form-input, button {
    width: 80%;
}

.form-input {
    border: none;
    border-radius: 30px;
    background: #f0f0f0;
    padding: 3%;
    padding-left: 5%;
    margin-bottom: 2%;
    resize: none;
    overflow-y: hidden;
}

.form-label {
    padding-left: 5%;
    margin-bottom: 1% ;
}

button {
    margin-top: 3%;
    padding: 3% 0;
    background-color: white;
    color: var(--purple);
    cursor: pointer;
    transition: .3s;
    border-radius: 30px;
    border: solid 2px var(--purple);
}

button:hover {
    transform: scale(1.1);
    border-radius: 30px;
    box-shadow: 2px 2px 8px #a1a1a1;
    background-color: var(--grey);
    color: white;
    border: solid 2px var(--grey);
}


@media only screen and (max-width: 600px) {
    .formContainer {
        width: 100%;
    }}

@media only screen and (max-width: 600px) {
    .form-label, .form-input, button {
        width: 100%;
}}

