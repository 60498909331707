.mid {
    display: flex;
    flex-direction: row;
    padding: 2%;
    width: 100%;
    justify-content: space-around;
    margin:0;
    background: rgb(247, 247, 247);
}


@media only screen and (max-width: 700px) {
    .mid {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 7%;
        background: rgb(247, 247, 247);
    }}